import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import CpapWrapper from "@components/layouts/cpapWrapper";
import ServiceForm from "@components/cpap/serviceform";
import { useDomainContext } from "@hooks/useDomainContext";

const PhilipsRecalls = dynamic(() => import("@components/cpap/philipsRecalls"));
const BottomSection = dynamic(() => import("@components/cpap/bottomSection"));
const OffersList = dynamic(() => import("@components/shared/offersList"));
export default function CarInjuryJusticeHome(): ReactElement {
    const { domain } = useDomainContext();
    return (
        <CpapWrapper>
            <ServiceForm />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <PhilipsRecalls />
            <BottomSection />
        </CpapWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
